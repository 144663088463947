//!USERS ============================================================= //
export const link_user_home_page = "/";
export const link_user_otp = "/otp";
export const link_user_otp_new = "/whatsapp-otp";
export const link_user_otp_verify = "/whatsapp-otp/verify";
export const link_user_voucher = "/vouchers";
export const link_user_stamps = "/stamps";
export const link_user_login = "/login";
export const link_user_pin = "/pin/login";
export const link_user_pin_create = "/pin/create";
export const link_user_pin_change = "/pin/change";
export const link_user_register = "/register";
export const link_user_register_pin = "/register/create-pin";
export const link_user_setting = "/settings";
export const link_user_setting_otp = "/settings/otp";
export const link_user_setting_add_email = "/settings/email/add";
export const link_user_setting_update_email = "/settings/email/update";
export const link_user_setting_verify_email = "/settings/email/verify";
//!USERS ============================================================= //

//!CASHIERS ========================================================== //
// export const link_cashier_home_page = "/cashiers";
// export const link_cashier_login = `${link_cashier_home_page}/login`;
// export const link_cashier_reward_history = `${link_cashier_home_page}/reward-history`;
// export const link_cashier_stamp_history = `${link_cashier_home_page}/stamp-history`;
// export const link_cashier_success_reward = `${link_cashier_home_page}/success-reward`;
//!CASHIERS ========================================================== //
