import axios from "axios";
import nookies from "nookies";
import Cookies from "js-cookie";
import { variablesMapper } from "../functions";
import { link_user_login } from "@components/page_url";

export const TokenValidation = (ctx) => {
  if (ctx) {
    //Check available token
    let token = nookies.get(ctx)[process.env.NEXT_PUBLIC_ACCESS_TOKEN];
    if (!token) return { user_status: false, token: null };
    return { user_status: true, token: token };
  } else {
    let token = Cookies.get(process.env.NEXT_PUBLIC_ACCESS_TOKEN);
    if (!token) return { user_status: false, token: null };
    return { user_status: true, token: token };
  }
};

/**
 * will return {data, errors}
 * @param {*} opt type: object
   sample: {
      method: "POST",
      variables: {...}, -> optional
      url: "/login" -> required
      auth: true => if need headers with Authorization Token -> optional
      ctx: Context from SSR -> optional (required if use on SSR / SSG / ISR)
   }
 */
export const AsyncAPI = async (opt) => {
  //validate arguments
  if (typeof opt !== "object")
    return { errors: { message: "Options parameter must be an object type" } };

  let { method, variables, url, auth, ctx } = opt;

  //Check if login / not
  let authToken;
  if (auth) {
    let { user_status, token } = TokenValidation(ctx);
    if (!user_status) return { errors: "Your not login" };
    else authToken = token;
  }

  //validate if no method
  if (!method)
    return { errors: { message: "Method is REQUIRED etc POST/GET" } };

  if (!url) return { errors: { message: "URL is required" } };

  //validate POST / GET

  try {
    let baseUrl =
      opt.type === "content"
        ? process.env.NEXT_PUBLIC_BASE_URL_CONTENT
        : process.env.NEXT_PUBLIC_BASE_URL;
    if (method?.toLowerCase() === "post") {
      let { data } = await axios.post(
        baseUrl + `${url}`,
        variables ? { ...variables } : null,
        auth
          ? {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          : null
      );
      return { data };
    } else if (method.toLowerCase() === "get") {
      let finalUrl = opt?.variables
        ? `${url}?${variablesMapper(opt?.variables)}`
        : url;
      let { data } = await axios.get(
        baseUrl + `${finalUrl}`,
        auth
          ? {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          : null
      );
      return { data };
    }
    return { errors: "Methos must be POST / GET" };
  } catch (error) {
    if (auth && error?.response?.status === 401) {
      if (ctx) {
        nookies.destroy(ctx, process.env.NEXT_PUBLIC_ACCESS_TOKEN);
        ctx.res
          .writeHead(302, {
            Location: `${link_user_login}?redirectAutoLogout=true`,
          })
          .end();
      } else {
        Cookies.remove(process.env.NEXT_PUBLIC_ACCESS_TOKEN);
        window.location.assign(`${link_user_login}?redirectAutoLogout=true`);
      }
    }
    return { errors: error };
  }
};
