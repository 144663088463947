import { AsyncAPI } from "../utils";

export const getInitialUser = async (token, ctx) => {
  let { data, errors } = await AsyncAPI({
    method: "GET",
    url: "/",
    auth: true,
    ctx,
  });
  return { data, errors };
};

export const fn_logout = async () => {
  //Will return bool
  let { data, errors } = await AsyncAPI({
    method: "POST",
    url: "/logout",
    auth: true,
  });

  if (data) {
    return { success: true };
  }
  return { success: false };
};

export const setStateFunc = (values, setState) =>
  setState((prev) => ({ ...prev, ...values }));

export const variablesMapper = (obj) =>
  Object.keys(obj)
    ?.map((item) => `${item}=${obj[item]}`)
    ?.join("&");

export const imgUrl = (path) =>
  `${process.env.NEXT_PUBLIC_STORAGE_URL}/${path}`;

export function truncate(inputString, maxLength) {
  if (inputString?.length <= maxLength) {
    return inputString;
  } else {
    const truncatedString = inputString?.slice(0, maxLength) + "...";
    return truncatedString;
  }
}

export function toTitleCase(str) {
  return str.replace(/\b\w/g, function (match) {
    return match.toUpperCase();
  });
}
