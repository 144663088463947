//Scss
import "react-datepicker/dist/react-datepicker.min.css";
import "src/assets/scss/anti.css";
import "src/assets/scss/main.scss";

//Utils
import App from "next/app";
import { Router } from "next/router";
import nProgress from "nprogress";
import { useEffect } from "react";
import TagManager from "react-gtm-module"; //! For datalayer
import { getInitialUser } from "src/lib/functions";
import { useWindowSize } from "src/lib/hooks";
import {
  ZustandProvider,
  initializeStore,
  useCreateStore,
} from "src/lib/store";
import { TokenValidation } from "src/lib/utils";

// NProgress Loading
nProgress.configure({ showSpinner: false });
Router.events.on("routeChangeStart", (url) => nProgress.start());
Router.events.on("routeChangeComplete", () => nProgress.done());
Router.events.on("routeChangeError", () => nProgress.done());

const MyApp = ({ Component, pageProps, initialZustandState }) => {
  // Utils ***************************************************************** //
  const createStore = useCreateStore(initialZustandState);
  const { width } = useWindowSize();
  const listWidth = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  };
  useEffect(() => {
    if (TagManager.initialize) {
      TagManager.initialize({
        gtmId: process.env.GOOGLE_TAG_MANAGER,
      });
    }
  }, []);
  // Utils ***************************************************************** //
  return (
    <ZustandProvider createStore={createStore}>
      <Component {...pageProps} width={width} listWidth={listWidth} />
    </ZustandProvider>
  );
};

export default MyApp;

MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);
  const zustandStore = initializeStore();

  let { user_status, token } = await TokenValidation(appContext.ctx);

  if (user_status) {
    let { data } = await getInitialUser(token, appContext.ctx);
    if (data?.data?.name) {
      const { setState } = zustandStore;
      setState({ user_data: { ...data?.data } });
    }
  }
  return {
    ...appProps,
    initialZustandState: JSON.parse(JSON.stringify(zustandStore.getState())),
  };
};
