export const initialState = {
  toast: {
    isToast: false,
    message: null,
    status: "",
  },
  user_data: null,
  isLoading: false,
  message: "",
};

export const stateReducer = (set, get) => ({
  setToast: (payload) => set({ toast: payload }),
  setUserData: (payload) => set({ user_data: payload }),
  setLoading: (payload) => set({ isLoading: payload }),
});
